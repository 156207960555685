import React from 'react';
// import Swiper core and required modules. More info at Swiperjs.com
import {Navigation, Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css'
import './testimonials.css';
//Import Testimonial information from a .JS file as array of objects
import {TestimonialData} from './TestimonialData.js';




const Testimonials = () => {
  return (
    <section id="testimonials">
      <h2 className='section-call-to-action'>~ Clients Thoughts ~</h2>
      <h1 className='section-title'>Testimonials</h1>
      <hr/>

      <Swiper className="container testimonials_container"
            // install Swiper modules
          modules={[Navigation, Pagination]}
          spaceBetween={40}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
      >

      {
      TestimonialData.reverse().map(({id, Avatar, name, review}) => {
            return(
                <SwiperSlide key={id} className="testimonial">
                  <div className="client_avatar">
                    <img src={Avatar} alt='Client Avatar' />
                  </div>
                    <h5 className='client_name'>{name}</h5>
                    <small className="client_review">{review}</small>
                </SwiperSlide>
              )
      })
      }

      </Swiper>{/* end of testimonials_container */}

    </section>
  )
}

export default Testimonials