import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/profile.png'
import HeaderSocials from './HeaderSocials'


const Header = () => {
  return (
      <header id="home">
          <div  className="container header_container">
              <h2 className='text-light'>Hello I'm</h2>
              <h1>Dominic Chaney</h1>
              <h2 className="text-light">Web Developer</h2>
              <CTA />
              <HeaderSocials />

              <div className='me'>
                <img src={ME} alt='' />
              </div>

              <a href='#contact' className='scroll_down'>Scroll Down</a>

          </div>
      </header>
  )
}

export default Header