import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

const Services = () => {
  return (
    <section id="services">
      <h2 className='section-call-to-action'>~ What I Offer ~</h2>
      <h1 className='section-title'>Services</h1>
      <hr/>

      <div className="container services_container">
        
        <article className="service">

          <div className="service_head">
            <h3>UI/UX Design</h3>
          </div>

          <ul className='service_list'>
            <li>
              <BiCheck className='service_list-icon' />
              <p>Amazing Visials</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>Beautiful Colours</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>Meaningful graphic designs</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>Perfect Typography</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>Super Interactive design</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>Information Architecture</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>Wireframing</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p style={{color:"var(--color-var-3)"}}>and more....</p>
            </li>

          </ul>
        </article>
        {/* End of UI/UX Design */}

        <article className="service">

          <div className="service_head">
            <h3>Web Development</h3>
          </div>

          <ul className='service_list'>
            <li>
              <BiCheck className='service_list-icon' />
              <p>Web Site Maintainance</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>Mobile optimization design</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>Custom Web application scripts</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>Login / Membership management</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>Search engine optimization</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>Database connections</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>Database data management</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p style={{color:"var(--color-var-3)"}}>and more....</p>
            </li>

          </ul>
        </article>
        {/* End of Web Development */}

        <article className="service">

          <div className="service_head">
            <h3>Content Creation</h3>
          </div>

          <ul className='service_list'>
            <li>
              <BiCheck className='service_list-icon' />
              <p>Infographic Design</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>Write custom site content</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>Edit current site content</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>white papers creation</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>keyword research</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>Video creation / editing</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>Newsletter content</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p style={{color:"var(--color-var-3)"}}>and more....</p>
            </li>

          </ul>
        </article>{/* End of Content Creation */}

      </div>{/* End of services container */}

    </section>
  )
}

export default Services