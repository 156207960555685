import React, { useState } from 'react'
import {useRef} from 'react'
import emailjs from 'emailjs-com'

 const ContactForm = () => {
     const [statusMessage, setStatusMessage] = useState("");
     const form = useRef();
   
     const sendEmail = (e) => {
       e.preventDefault();

     // emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_PUBLIC_KEY')
       emailjs.sendForm('service_5kbyocl', 'template_q7t0fwm', form.current, 'kMF3XZ5ihiyrojwHH')
          .then((result) => {
              console.log(result.text);
                setStatusMessage("Email Sent Successfully!");
          }, (error) => {
              console.log(error.text);
                setStatusMessage(`${error.text} happened`);
          });

          e.target.reset();
     };
   
     return (
          <div>
              <form ref={form} onSubmit={sendEmail}>
                  <input className='formTextSize' type="text" name="name" placeholder='Your Full Name' required />
                  <input className='formTextSize' type="email" name="email" placeholder='Your Email' required />

                  <textarea className='formTextSize' name="message" placeholder='Enter Your Message' rows="10" required></textarea>

                  <button className='btn btn-primary formSubmit' type="submit" >Send Message</button>

              </form>

              <div className='formstatus'>
                <p>{statusMessage}</p>
              </div>

          </div>
     ) 
  };

export default ContactForm;