import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {IoLogoTwitter} from 'react-icons/io'
import {FaFacebookF} from 'react-icons/fa'

const HeaderSocials = () => {
  return (
    <div className="header_socials">

      <div className='tooltip2'>
        <a id="social-1"  href='https://Linkedin.com/in/Dominic-A-Chaney' target="_blank" rel="noopener noreferrer"><BsLinkedin /></a>
        <p className='social tip1'>LinkedIn</p>
      </div>

      <div className='tooltip2'>
        <a id="social-2" href='https://twitter.com/dominicachaney' target="_blank" rel="noopener noreferrer"><IoLogoTwitter /></a>
        <p className='social tip2'>Twitter</p>
      </div>

      <div className='tooltip2'>
        <a id="social-3" href='https://facebook.com/dominic.chaney' target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
        <p className='social tip3'>Facebook</p>
      </div>

    </div>
  )
}

export default HeaderSocials