import React from 'react'
import './experience.css'
import {BsPatchCheckFill} from 'react-icons/bs'


const Experience = () => {
  return (

    <section id="experience">
      <h2 className='section-call-to-action'>~ What skills do I have ~</h2>
      <h1 className='section-title'>My Experience</h1>
      <hr/>
      <div className="container experience_container">
        <div className="exerience_frontend">
          <h3>Frontend Development</h3>
          <div className="experience_content">

            <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon'/>
                <div>
                  <h4>HTML</h4>
                  <small className='text-light'>Experienced</small>
                </div>
            </article>

            <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon'/>
                <div>
                  <h4>CSS</h4>
                  <small className='text-light'>Experienced</small>
                </div>
            </article>

            <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon'/>
                <div>
                  <h4>Javascript</h4>
                  <small className='text-light'>Intermediate</small>
                </div>
            </article>

            <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon'/>
                <div>
                  <h4>Bootstrap</h4>
                  <small className='text-light'>Intermediate</small>
                </div>
            </article>

            <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon'/>
                <div>
                  <h4>SASS</h4>
                  <small className='text-light'>Intermediate</small>
                </div>
            </article>

            <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon'/>
                <div>
                  <h4>React</h4>
                  <small className='text-light'>Intermediate</small>
                </div>
            </article>

          </div>
        </div>{/* End of frontend */}

        <div className="experience_backend">
          <h3>Backend Development</h3>
            <div className="experience_content">

              <article className='experience_details'>
                <BsPatchCheckFill className='experience_details-icon'/>
                  <div>
                    <h4>PHP</h4>
                    <small className='text-light'>Intermediate</small>
                  </div>
              </article>

              <article className='experience_details'>
                <BsPatchCheckFill className='experience_details-icon'/>
                  <div>
                    <h4>Node JS</h4>
                    <small className='text-light'>Intermediate</small>
                  </div>
              </article>

              <article className='experience_details'>
                <BsPatchCheckFill className='experience_details-icon'/>
                  <div>
                    <h4>MySQL</h4>
                    <small className='text-light'>Intermediate</small>
                  </div>
              </article>

              <article className='experience_details'>
                <BsPatchCheckFill className='experience_details-icon'/>
                  <div>
                    <h4>MongoDB</h4>
                    <small className='text-light'>Intermediate</small>
                  </div>
              </article>

              <article className='experience_details'>
                <BsPatchCheckFill className='experience_details-icon'/>
                  <div>
                    <h4>Python</h4>
                    <small className='text-light'>Beginner</small>
                  </div>
              </article>

            </div>
        </div>{/* End of Backend */}

      </div>{/* End of experiences container */}
    </section>


  )//return

};//function

export default Experience