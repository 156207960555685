import React from 'react'
import './about.css'
import ME from '../../assets/greentoblue.jpg'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>

        <h2 className='section-call-to-action'>~ Get to know more ~</h2>
        <h1 className='section-title'>About Me</h1>
        <hr/>

        <div className="container about_container">
          <div className="about_me">
            <div className="about_me-image">
              <img src={ME} alt="About Me" />
            </div>
          </div>

          <div className="about_content">
            <div className="about_cards">

                <article className='about_card'>
                  <FaAward  className="about_icon"/>
                  <h5>Experience</h5>
                    <small>6+ years</small>
                </article>

                <article className='about_card'>
                  <FiUsers  className="about_icon"/>
                  <h5>Clients</h5>
                    <small>7+ Connnections</small>
                </article>

                <article className='about_card'>
                  <VscFolderLibrary  className="about_icon"/>
                  <h5>Projects</h5>
                    <small>10+ Completed</small>
                </article>

            </div>{/* about_cards */}

          <p style={{marginBottom: "1rem"}}>
          Hello, my name is Dominic Chaney. Thank you for viewing my React JS portfolio. Web development is something that I extremely love to do. I am always excited to work with other amazing developers and enjoy continuously learning as technology changes. My 6+ years of coding have taught me that anything is possible to build if you maintain a vast knowledge of resources. I am always up for new challenges as I am here to bring your visions to life. Hope to work with you soon!  
          </p>
          
          <a href='#contact' className='btn btn-primary'>Let's Talk</a>

          </div>
          </div>
      
    </section>
  )
};

export default About