import React from 'react'
import './nav.css'
import {AiOutlineHome} from 'react-icons/ai'
import {AiOutlineUser} from 'react-icons/ai'
import {BiBook} from 'react-icons/bi'
import {RiServiceLine} from 'react-icons/ri'
import {BiMessageSquareDetail} from 'react-icons/bi'
import {useState} from 'react'


const Nav = () => {
  const [activeNav, setActiveNav] = useState('#');
  const [isVisible, setIsVisible] = useState(true);

  window.addEventListener('scroll', () =>{
    const scrollpos = window.scrollY;
    const scrollable = document.documentElement.scrollHeight - window.innerHeight;

    var scrollmax = scrollable - 300;

    if(scrollpos >= scrollmax){

         setIsVisible(false);
         return isVisible;

    }else if(scrollpos < scrollmax)
         {
              setIsVisible(true);
              return isVisible;
         }
    
    }
  )

  return (
    <nav style={{visibility: isVisible ? 'visible' : 'hidden'}}>
      <div className="tooltip">
        <a href='#home' 
          onClick={() => setActiveNav('#')} 
          className={activeNav === '#home' ? 'active' : ''}>
          <AiOutlineHome />
        </a>

        <span className="tooltiptext">Home</span>
      </div>

      <div className="tooltip">
        <a href='#about' 
          onClick={() => setActiveNav('#about')} 
          className={activeNav === '#about' ? 'active' : ''}>
          <AiOutlineUser />
        </a>

        <span className="tooltiptext">About</span>
      </div>
      
      <div className="tooltip">
        <a href='#experience' 
          onClick={() => setActiveNav('#experience')} 
          className={activeNav === '#experience' ? 'active' : ''}>
          <BiBook />
        </a>

        <span className="tooltiptext2">Experience</span>
      </div>
      
      <div className="tooltip">
        <a href='#services' 
        onClick={() => setActiveNav('#services')} 
        className={activeNav === '#services' ? 'active' : ''}>
        <RiServiceLine />
        </a>

        <span className="tooltiptext3">Services</span>
      </div>

      <div className="tooltip">
        <a href='#contact' 
        onClick={() => setActiveNav('#contact')} 
        className={activeNav === '#contact' ? 'active' : ''}>
        <BiMessageSquareDetail />
        </a>

        <span className="tooltiptext3">Contact</span>
      </div>
    
    </nav>
  )
};

export default Nav