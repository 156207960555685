import React from 'react'
import './portfolio.css'
import {portfolioData} from './PortfolioArray' //Import Portfolio work

import { useRef, useState } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css'

import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";


const Portfolio = () => {
  return (
    <section id="portfolio">
      <h2 className='section-call-to-action'>~ My Recent Work ~</h2>
      <h1 className='section-title'>Portfolio</h1>
      <hr/>

      <Swiper className='container portfolio'

      modules={[Navigation, Pagination, Mousewheel, Keyboard]}
      cssMode={true}
      navigation={true}
      pagination={{ clickable: true }}
      mousewheel={true}
      keyboard={true}
      spaceBetween={20}
      breakpoints={{
        240:{
          slidesPerView: 1,
        },
        // when window width is >= 768px
        768: {
          slidesPerView: 2,
        },
        // when window width is >= 1024px
        //1500: {
          //sidesPerView: 3,
        //},
      }}
      
      >
        {
          //Each Portfolio item for this array map below are in the file PortfolioArray.js found in the includes folder
          portfolioData.map(({id, image, title, tech, date, github, demo}) => {
            return(
              <SwiperSlide key={id} className='portfolio_item'>
                <div className='portfolio_item-image'>
                  <img src={image} alt="portfolio screen shot" />
                  <h3>{title}</h3>
                  {/*<h5>{date}</h5>*/}
                  <h4>{tech}</h4>
                  <div className="portfolio_item-cta">
                    {
                      //The condition below checks if a github link is set in the data array. If not a button is not displayed.
                      github ?   <a href={github} className='btn-portfolio' target="_blank" rel="noopener noreferrer">Github</a>
                      : ""
                    }
                   
                    {
                      //Condition below checks if a demo link is set in the data array. If not the button link is not displayed. 
                      demo ? <a href={demo} className='btn-portfolio' target="_blank" rel="noopener noreferrer">Live Demo</a>
                      : ""
                    }
                  </div>
              </div>
            </SwiperSlide>
            )
          })
        }

      </Swiper> {/* End of portfolio_container */}
    </section>
  )
}

export default Portfolio