import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import {BsWhatsapp} from 'react-icons/bs'
import ContactForm from './ContactForm'

const Contact = () => {
  return (
    <section id="contact">
      <h2 className='section-call-to-action'>~ Please  Feel Free To ~</h2>
      <h1 className='section-title'>Contact Me</h1>
      <hr/>

      <div className="container contact_container">
        <div className="contact_options">

        <article className="contact_option">
            <BsWhatsapp className='contact_option-icon'/>
            <h4>Cell Phone</h4>
            <h5>903.452.3883</h5>
            {/*<a href="https://api.whatsapp.com/send?phone+19037420573" rel="noopener noreferrer" target="_blank">Text/Call</a>*/}
          </article>
          
          <article className="contact_option">
            <MdOutlineEmail className='contact_option-icon'/>
            <h4>Email</h4>
            <h5>me@dominicchaney.com</h5>
            <a href="mailto:me@dominicchaney.com" rel="noopener noreferrer" target="_blank">Email Me</a>
          </article>

          <article className="contact_option">
            <RiMessengerLine className='contact_option-icon'/>
            <h4>Messenger</h4>
            <h5>Dominics Chaney</h5>
            <a href="https://m.me/Dominic.chaney" rel="noopener noreferrer" target="_blank">Message Me</a>
          </article>

        </div>{/* End of Contact Options */}

        <ContactForm />

      </div>{/* container */}
    </section>
  )
}

export default Contact