import React from 'react'
import RESUME from '../../assets/Dominic_web_resume.pdf'


const CTA = () => {
  return (
    <div className="cta">
        <a className='btn'  href={RESUME} download >Resume</a>
        <a href='#contact' className='btn btn-primary'>Let's Talk</a>
    </div>
  )
}

export default CTA