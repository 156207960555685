import AVTR1 from '../../assets/avatar1.jpg'
import AVTR2 from '../../assets/avatar2.jpg'
import AVTR3 from '../../assets/avatar3.jpg'
import AVTR4 from '../../assets/avatar4.jpg'

export const TestimonialData = [
     {
       id: 1, 
       Avatar: AVTR3,
       name: "Eli Logan",
       review: 'Dominic joined our Event Equity Partners team as a front end developer. This young man was very eager to accomplish each and every project he was. He was a smart and bright individual who loves web design.', 
     },

     {
     id: 2, 
     Avatar: AVTR4,
     name: "E-TUK",
     review: 'Dominic help us with the development of our music web application. I was very impressed with his ability to build the application from the ground up. Dominic we absolutely thank you for making our vision come alive.', 
     }
   ];
