import IMG1 from '../../assets/texaslamborghini-thumb.jpg'
import IMG2 from '../../assets/zebralot-thumb.jpg'
import IMG3 from '../../assets/Potmmusic-thumb.jpg'
import IMG4 from '../../assets/portfolio4.jpg'
import IMG5 from '../../assets/portfolio5.png'
import IMG6 from '../../assets/portfolio6.jpg'

export const portfolioData = [
  {
    id: 1, 
    image: IMG1,
    title: "Texas Lamborghini",
    tech: "Technologies: Bootstrap, Javascript, Php",
    date: 'Oct 2016',
    demo: 'http://texaslamborghini.org/'
  },
  {
    id: 2, 
    image: IMG2,
    title: "Zebralot Restorations",
    tech: "Technologies: Bootstrap, Javascript, Php, HTML, CSS",
    date: 'Feb 2017',
    demo: 'http://zebralot.online/home'
  },
  /*
  {
    id: 3, 
    image: IMG3,
    title: "Potm Music site",
    tech: "Technologies: Bootstrap, Javascript, Php, MySql",
    date: 'May 2020',
    demo: 'http://potmmusic.com'
  },
  {
    id: 4, 
    image: IMG4,
    title: "This is a portfolio item title",
    tech: "Technologies: HTML, CSS",
    github: 'https://github.com',
    demo: 'https://dribbble.com'
  },
  {
    id: 5, 
    image: IMG5,
    title: "This is a portfolio item title",
    tech: "Technologies: HTML, CSS",
    github: 'https://github.com', 
    demo: 'https://dribbble.com'
  },
  {
    id: 6, 
    image: IMG6,
    title: "This is a portfolio item title",
    tech: "Technologies: HTML, CSS",
    github: 'https://github.com', 
    demo: 'https://dribbble.com'
  }*/ 
];
